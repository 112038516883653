<template>
  <div>
    <div class="d-flex m align-items-center justify-content-between">
      <button class="px-0 btn" @click="returnToView">
        <b-icon icon="arrow-left"></b-icon>
      </button>
      <Button v-if="viewType == 'default'" @click="toggleView('addCustomInput')"
        >Add New</Button
      >
    </div>

    <div v-if="viewType == 'default'">
      <div class=" pt-3">
        <div class="">
          <div class="">
            <div
              class="loader d-flex align-items-center justify-content-between"
            >
              <div class="d-flex align-items-center" v-if="isLoading">
                <b-spinner small style="color: #4d1b7e"></b-spinner>
                <span class="ml-2">Loading Custom Inputs</span>
              </div>
            </div>
            <div class="skeleton" v-if="isLoadingFirstTime">
              <b-skeleton-table
                class="py-3"
                :rows="5"
                :columns="4"
                :table-props="{
                  bordered: true,
                  striped: true,
                }"
              ></b-skeleton-table>
            </div>
            <div class="data-table" v-else-if="items.length > 0">
              <b-table
                head-class="text-center"
                class="leadTable"
                :stickyColumn="true"
                hover
                :fields="fields"
                :items="items"
              >
                <template #cell(field_name)="data">
                  <div class="text-capitalize">
                    {{ data.item.field_name.replaceAll("_", " ") }}
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div class="d-flex justify-content-center align-items-center">
                    <div class="p-2" v-if="isDeleteLoading == data.item.id">
                      <b-spinner small></b-spinner>
                    </div>

                    <button
                      v-else
                      class="remove-btn btn"
                      title="Delete"
                      @click="initiateDeleteCustom(data.item.id)"
                    >
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                </template>
              </b-table>
            </div>
            <template v-else>
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
              <EmptyTable >
<template #title>
No custom input found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-if="viewType == 'addCustomInput'" class="px-5">
      <div class="" style="max-height: 450px">
        <div class="">
          <label for="webhook-events" class="text-uppercase"
            >Field Name:
          </label>
          <div class="w-100">
            <input
              type="text"
              name=""
              v-model="customInput.field_name"
              class="quizell-form-control w-100 px-2"
              id=""
            />
            <p v-if="error" class="text-danger py-1" style="font-size: 12px">
              {{ error }}
            </p>
          </div>
        </div>
        <div class="my-3 d-flex flex-column align-items-center">
          <label for="webhook-events" class="text-uppercase">Field Type:</label>
          <div class="d-flex flex-wrap" style="gap: 40px">
            <b-form-radio
              name="field_type"
              class="radio-button cursor-pointer"
              value="text"
              v-b-tooltip.hover :title="`Enter short, specific details or titles.`"
              v-model="customInput.field_type"
              >Text</b-form-radio
            >
            <b-form-radio
              name="field_type"
              value="textarea"
              v-b-tooltip.hover :title="`Add detailed explanations or paragraphs.`"
              v-model="customInput.field_type"
              class="radio-button cursor-pointer"
              >Textarea</b-form-radio
            >
            <b-form-radio
              name="field_type"
              value="date"
              v-b-tooltip.hover :title="`Select or input dates for events or deadlines.`"
              v-model="customInput.field_type"
              class="radio-button cursor-pointer"
              >Date</b-form-radio
            >
          </div>
        </div>
      </div>
      <div class="d-flex mt-2 align-items-center justify-content-center">
        <Button :is-loading="isCreateLoading" @click="saveInputField">Create</Button>
      </div>
    </div>
    <div v-if="viewType == 'deleteCustom'">
        <div>

                <p class="" style="text-align: center;font-size:17px;font-weight:500;">
                  Are you sure you want to delete the Custom Input? This can’t
                  be undone.
                </p>
               
                  <div class="w-100 d-flex justify-content-center">
                    <b-button
                      :class="isDeleteLoading ? 'disabled' : ''"
                      variant="danger"
                      @click="DeleteCustomInput()">
                      <span v-if="!isDeleteLoading"> Confirm </span>
                      <div
                        v-else
                        class="spinner-border spinner-border-sm"
                        role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </b-button>
                  </div>
                
        </div>
    </div>
  </div>
</template>
<script>
EmptyTable
import axios from "axios"
import Button from "../../../Dashboard/Layout/Button.vue";
import EmptyTable from "../../../Dashboard/Layout/EmptyTable.vue";
export default {
  components: {
    Button,
    EmptyTable
  },
  data() {
    return {
      viewType: "default",
      fields: [
        {
          key: "field_name",
          label: "Field Name",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "field_type",
          label: "Field Type",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "actions",
          label: "Actions",
          tdClass:
            "align-middle data-Table d-flex justify-content-end cursor-pointer",
          thClass: "bg-table-heading text-right",
        },
      ],

      items: [],
      isLoadingFirstTime: true,
      isLoading: false,
      isDeleteLoading: false,
      deleteInputId: null,
      deleteLoadingRow: false,

      //  Add Custom Fields
      customInput: {
        field_name: "",
        field_type: "text",
      },
      error: false,
      isCreateLoading: false,
    };
  },
  watch: {
    customInput: {
      handler() {
        this.validateInput();
      },
      deep: true,
    },
  },
  methods: {
    initiateDeleteCustom(id){
        if(id){
            this.deleteInputId = id

            this.toggleView(`deleteCustom`)
        }
       
    },
    returnToView(){
        if(this.viewType !== `default` ){
            this.viewType = 'default'
        } else {
            this.$emit('toggleView')
        }
    },
    toggleView(value){
        this.viewType = value
    },
    validateInput() {
      // Use a regular expression to check for invalid characters
      if (/[^a-zA-Z0-9_]/.test(this.customInput.field_name)) {
        this.error =
          "Please enter only alphanumeric characters and underscores.";
      } else if (this.customInput.field_name == "") {
        this.error = "Field Name must be required.";
      } else {
        this.error = "";
      }
    },
    async saveInputField() {
      this.validateInput();
      if (this.error != "" || this.customInput.field_type == "") {
        return false;
      }

      try {
        this.isCreateLoading = true;
      const response = await axios.post(`/custom/field/create`,this.customInput)
      if (response && response.status == 200) {
        this.$toasted.show(`Input Field Created Successfully!`, {
          theme: "toasted-primary",

          position: "bottom-center",
          duration: 2000,
        });
        
        this.$emit('toggleView')
        // this.getCustomInputs()
       
        this.customInput = {
          field_name: "",
          field_type: "text",
        };
      } 
      } catch (error) {
      

          const {errors} =error.response.data
          console.log("Error",errors)

          if(errors['field_name'] && Array.isArray(errors['field_name'])){
            for(const item of errors['field_name'] ){
              this.$toasted.show(item, {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
            }

            return 
          }

        this.$toasted.show(`Error Occurred`, {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
        
      } finally{
        this.isCreateLoading = false;
      }

   

      
    },
    async DeleteCustomInput() {
      this.isDeleteLoading = true;
      const response = await this.$store.dispatch("fetchData", {
        axiosMethod: "delete",
        resource: "customInputs",
        method: "delete",
        id: this.deleteInputId,
      });
      if (response && response.status == "success") {
        this.$toasted.show(`Custom Input Deleted Successfully!`, {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        this.deleteInputId == null
        this.viewType = 'default'
        this.getCustomInputs()
      } else
        this.$toasted.show(`Error Occurred`, {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });

      this.isDeleteLoading = false;
    },
    async getCustomInputs() {
      this.isLoading = true;
      const response = await this.$store.dispatch("fetchData", {
        axiosMethod: "get",
        resource: "customInputs",
        method: "list",
      });
      if (response) {
        this.items = response.data;
      }
      this.isLoadingFirstTime = false;
      this.isLoading = false;
    },
  },
  mounted() {
    this.getCustomInputs();
  },
};
</script>
<style scoped>
label {
  color: rgba(115, 115, 141, 1);
  font-size: 12px;
}
.radio-button
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background: #ffa201;
  border-color: #ffa201;
}
.radio-button
  >>> .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-radius: 50%;
}

</style>
